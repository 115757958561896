import React from "react"
import Service from "../../components/Shared/Service"
import checkMark from "../../assets/icons/noun-check-mark.svg"
import devopslifecycle from "../../assets/icons/devopsLifeCycle.svg"
import migrateToCloud from "../../assets/icons/migrateToCloud.svg"
import applicationModernization from "../../assets/icons/applicationModernization1.svg"
import { graphql } from "gatsby"

const seoTitle = "Amazon Web Services"
const seoDescription =
  "Atyeti's modernization capability helps you determine the right path to take to improve your current applications or to replace them with entirely new ones."
const keyword =
  " Application modernization, modernization, Kubernetes, Microservices, Refactoring, DevOps, Agile, Cloud Migration Automated Testing, Replatforming"
const mainHeading = "Amazon Web Services"
// const mainPara = `As a Google Cloud partner Engineering Services designed for Google Cloud.`
const mainPara = `Unlock the power of AWS with Atyeti's tailored cloud solutions. As an AWS Partner, 
we specialize in cloud migration, scalable infrastructure design, data management, 
and intelligent automation to streamline operations and reduce costs. Our certified 
AWS experts empower businesses to drive innovation, enhance agility, and achieve measurable growth. 
Partner with Atyeti to transform your operations, harness the value of your data, and realize the full 
potential of the AWS cloud with confidence.`
const serviceIcons = [
  { id: 1, name: "Cloud Security", icon: checkMark },
  {
    id: 2,
    name: "Infrastructure Modernization",
    icon: checkMark,
  },
  { id: 3, name: "Cloud Migration", icon: checkMark },
  { id: 4, name: "Data Engineering and Analytics", icon: checkMark },
  { id: 5, name: "Data Management", icon: checkMark },
  { id: 6, name: "Cloud Cost Optimization", icon: checkMark },
  { id: 7, name: "AI/RAG and Intelligent Automation", icon: checkMark },
  { id: 8, name: "Replatforming/ Multiplatforming", icon: checkMark },
]

const section2Heading = `Our Services`
const section2Para = `Our services in AWS include Infrastructure Services, Data Warehousing and AI, ML.
<br/> Learn more about our <a href="/cloud-solutions"> cloud services.</a>`

const cardItems = [
  {
    id: 1,
    icon: applicationModernization,
    heading: `Infrastructure`,
    listItems: (
      <>
        <li> Cloud Migration</li>
        <li>Anthos: Hybrid/Multi-cloud App platform,</li>
        <li> Custom-built app migration</li>
        <li> Improve software delivery pipeline</li>
        <li>Security</li>
        <li>Cloud Cost Governance</li>
      </>
    ),
  },
  {
    id: 2,
    icon: migrateToCloud,
    heading: `Data`,
    listItems: (
      <>
        <li>Data Strategy Development</li>
        <li>Data Governance, Risk, Compliance</li>
        <li>Data Analytics and Visualization</li>
        <li>Warehouse Workloads to AWS</li>
        <li>Spark/ Databricks Workloads to AWS</li>
        <li>Data Sharing/ Monetization</li>
      </>
    ),
  },
  {
    id: 3,
    icon: devopslifecycle,
    heading: `AI,ML`,
    listItems: (
      <>
        <li>Defining the AI Roadmap: estimate the ROI</li>
        <li>CM/CT Automation Design</li>
        <li>Intelligent Automation</li>
        <li>AI RAG Systems (vector, graph, relational)</li>
        <li>Parameterized Meta Prompting</li>
        <li>Agentic Workflow Automation</li>
      </>
    ),
  },
]

const ServiceDetails = ({ data }) => (
  <Service
    seoTitle={seoTitle}
    mainHeading={mainHeading}
    mainPara={mainPara}
    serviceIcons={serviceIcons}
    // section2Heading={section2Heading}
    cardItems={cardItems}
    section2Para={section2Para}
    caseItems={data}
    seoDescription={seoDescription}
    keyword={keyword}
    className="amazon-web-services"
  />
)
export const query = graphql`
  query AWSQuery {
    casestudyJson(mainheading: { eq: "amazon-web-services" }) {
      data {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        para
        slug
        subheading
      }
      bannerimage {
        heading
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
export default ServiceDetails
